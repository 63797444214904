import Rails from "@rails/ujs"

import "jquery"

import "packs/login"

Rails.start()



