$( function() {
  resizeImage();
  $(window).on('resize', resizeImage);
});

function resizeImage() {
  boxWidth = $('.my-content-box').width();
  $('.my-header img').width(boxWidth);

  console.log(boxWidth);
}